<template>
	<div style="height:100%; width:100% ; overflow: auto;">

		<div class="rowitem" v-for="(item,idx) in DataList" :key="item.id+item.warning_desc+Math.random()*10000" @click="">
			<div class="rowtitle">
				{{item.stu_name}}
			</div>
			<div class="rowtitle">
				{{item.class_name}}
			</div>
			<div class="rowdate">
				{{item.warning_desc}}
			</div>
		</div>

	</div>
</template>

<script>

export default {

	data() {
		return {
			DataList: []

		};
	},
	mounted() {

		this.getData(0,0)
	},
	methods: {
		getData(warning_status,class_id) {
			this.$http.post("/api/home_warning",{warning_status:warning_status,class_id:class_id}).then(res => {
				this.DataList = res.data

			})
		},
		exportXls(warning_status,class_id){
			this.$http.post("/api/export_home_warning_excel",{warning_status:warning_status,class_id:class_id}).then(res => {
				window.open(res.data.url,"_blank")
			})
		}


	}
}


</script>

<style scoped="scoped">

.rowitem{ display: flex; font-size: 14px; line-height: 30px; cursor: pointer; border-bottom: 1px solid #eee;}
.rowtitle{ width: 25%; overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }
.rowdate{ width: 50%; text-align: center; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
</style>
