<template>
	<div style="height:100%; width:100% ; overflow: auto;">

		<div class="rowitem" v-for="(item,idx) in DataList" :key="item.id" @click="">
			<div class="rowtitle">
				{{item.name}}-{{item.class_name}}-{{item.total_score}}分，排名第：{{item.ranking}}名
			</div>
			
		</div>

	</div>
</template>

<script>

export default {

	data() {
		return {
			DataList: []

		};
	},
	mounted() {

		this.getData()
	},
	methods: {
		getData() {
			this.$http.post("/api/performance_rankings",{compute_type:2}).then(res => {
				this.DataList = res.data

			})
		},


	}
}


</script>

<style scoped="scoped">

.rowitem{ display: flex; font-size: 14px; line-height: 30px; cursor: pointer; border-bottom: 1px solid #eee;}
.rowtitle{ width: 100%; overflow: hidden;white-space: nowrap; text-overflow: ellipsis; }
.rowdate{ width: 20%; text-align: center; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
</style>
