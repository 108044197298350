<template>
	<div>
		<!-- <tchtree v-model="testTree" @change="getTestTreeData"></tchtree>
		{{testTree}} -->
		<div class="headerbox">
			<div class="headitem" @click="goPage('/xflist')">
				<img :src="'/img/xunfang.png'" />
				<span style="color:#1976D3">巡访</span>
			</div>
			<div class="headitem" @click="goPage('/datatj_zhoubao')">
				<img :src="'/img/week.png'" />
				<span style="color:#1AB0D6">周报</span>
			</div>
			<div class="headitem" @click="goPage('/datatj_yuebao')">
				<img :src="'/img/month.png'" />
				<span style="color:#21ADB7">月报</span>
			</div>
			<div class="headitem" @click="goPage('/stu_sign')">
				<img :src="'/img/summary.png'" />
				<span style="color:#008CD3">打卡</span>
			</div>
			<div class="headitem" @click="goPage('/shixisp')">
				<img :src="'/img/intership.png'" />
				<span style="color:#0284DC">实习</span>
			</div>
			<div class="headitem" @click="goPage('/91job')">
				<img :src="'/img/leave.png'" />
				<span style="color:#3F91BB">91JOB</span>
			</div>
			<div class="headitem" @click="goPage('/24365',2)">
				<img :src="'/img/24365.png'" />
				<span style="color:#2fa4e7">24365</span>
			</div>

		</div>

		<el-row :gutter="20">
			<el-col :span="15">
				<div style="margin-bottom:20px">
					<el-card>
						<div slot="header" class="clearfix">
							<span>实习概况</span>
							<el-button style="float: right; padding: 3px" size="mini" type="primary"
								@click="goPage('/datatj')">
								更多 <i class="el-icon-d-arrow-right"></i>
							</el-button>
						</div>
						<div style="height:150px">
							<sxgk></sxgk>

						</div>
					</el-card>
				</div>

				<el-row :gutter="20">
					<el-col :span="12">
						<el-card>
							<div slot="header" class="clearfix">
								<span>通知公告</span>
								<el-button style="float: right; padding: 3px" size="mini" type="primary"
									@click="goPage('/notify')">
									更多 <i class="el-icon-d-arrow-right"></i>
								</el-button>
							</div>
							<div style="height:300px;overflow: auto;">
								<div class="rowitem" v-for="(item,idx) in NotifyList" :key="item.id"
									@click="article_id=item.id">
									<div class="rowtitle">
										{{item.title}}
									</div>
									<div class="rowdate">
										{{item.cdate}}
									</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="12">
						<el-card>
							<div slot="header" class="clearfix">
								<span>待办事项</span>

							</div>
							<div style="height:300px">
								<daiban></daiban>
							</div>
						</el-card>
					</el-col>
				</el-row>

				<div style="margin-top:20px">
					<el-card>
						<div slot="header" class="clearfix">
							<span>预警</span>
							<el-select @change="getYujing" v-model="yujingStatus" size="small" style="width: 150px;margin-left:10px">
								<el-option :value="0" label="全部"></el-option>
								<el-option :value="1" label="预警名单"></el-option>
								<el-option :value="2" label="高危名单"></el-option>
							</el-select>
							<el-cascader  ref="firstCascader" :options="ckdTreeData" :props="defaultProps"
								v-model="deptId" @change="getYujing" :show-all-levels="false" clearable
								placeholder="请选择" filterable style="width: 200px;margin-left:10px" size="small">
							</el-cascader>


							<el-button style="float: right; padding: 3px" size="mini" type="primary" @click="exportYujing">
								导出预警信息 <i class="el-icon-d-arrow-right"></i>
							</el-button>
						</div>

						<div style="height:200px">
							<yujing ref="yujing"></yujing>
						</div>
					</el-card>
				</div>
			</el-col>
			<el-col :span="9">
				<div style="margin-bottom:20px">
					<el-card>
						<div slot="header" class="clearfix">
							<span>教师考核</span>

						</div>

						<div style="height:400px">
							<jskh ref="jskh"></jskh>
						</div>

					</el-card>
				</div>
				<div style="margin-bottom:20px">
					<el-card>
						<div slot="header" class="clearfix">
							<span>优秀实习生</span>
							

						</div>

						<div style="height:400px"></div>

					</el-card>
				</div>
			</el-col>
		</el-row>

		<phone v-if="article_id" :id="article_id"></phone>
		
	</div>
</template>

<script>
import phone from '../notify/articleinfo.vue'
import daiban from './daiban.vue'
import yujing from './yujing.vue'
import jskh from './jskh.vue'
import tchtree from '../com/tchTree.vue'
export default {
	components: {
    phone,
    sxgk: () => {
      if (window.pmid == 221 ) {
        return import("./wxjd/wxjd_sxgk.vue")
      } else {
        return import("./sxgk.vue")
      }
    },
    daiban,
    yujing,
    jskh,
    tchtree
  },
	data() {
		return {
			NotifyList: [],
			article_id: 0,
			defaultProps: {
				label: 'name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			deptId: 0,
			yujingStatus:0,
			ckdTreeData: [],
			testTree:""

		};
	},
	mounted() {
		this.getNotify()
		this.getTree()

	},
	watch:{
		testTree:function(n,o){
			console.log(n)
		}
	},
	methods: {
		getTestTreeData(e){
			console.log(e)
		},
		getDeptID(e){
			console.log("getDeptID")
			console.log(e)
			console.log(this.deptId)
		},
		getTree() {

			this.$http.post("/api/get_tch_cls_data", {}).then(res => {
				res.data.list.map(e => {
					e.name = e.upname + "/" + e.name
				})
				this.treeData = res.data.list
				this.ckdTreeData = this.treeData
			})
		},
		getNotify() {
			this.$http.post("/api/notify_list_list").then(res => {
				for (let item of res.data.data) {
					item.cdate = item.cdate.split(' ')[0]
				}
				this.NotifyList = res.data.data
			})
		},
		closePhone() {
			this.article_id = 0
		},
		goPage(path, type) {
			if (type == 2) {
				window.open('https://www.ncss.cn/')
			} else {
			this.$router.push(path)
			}
		},
		getYujing(){
			this.$refs['yujing'].getData(this.yujingStatus,this.deptId)
		},
		exportYujing(){
			this.$refs['yujing'].exportXls(this.yujingStatus,this.deptId)
		}
		
	}
}


</script>

<style scoped="scoped">
.headerbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	padding: 20px 1px;
}

.headitem {
	border: 0px solid #eee;
	box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 60px 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	box-sizing: border-box;
}

.headitem img {
	width: 40px;
	height: 40px;
}

.headitem span {
	padding-left: 20px;
	font-size: 18px;
}

.rowitem {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
}

.rowtitle {
	width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.rowdate {
	width: 20%;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
</style>
